import React from "react";

const VideoOut = ({ videoId }) => {
  return (
    <div className="video-out">
      <div className="video-out-res">
        <iframe
          max-width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="video-screen"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoOut;
