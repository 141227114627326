import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import axios from "axios";
import { Link } from "gatsby";
import ContrastSection from "../../components/ContrastSection";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import { Container } from "reactstrap";
import VideoOut from "../../components/videoOut";
import Iit from "../../images/iit.png";
import Sliit from "../../images/sliit.png";
import Mora from "../../images/mora.png";
import Uva from "../../images/uva.jpg";
import SvgIconsSet from "../../components/SvgIconsSet";
import GetInTouchbtn from "../../components/GetInTouchbtn";
import hero from "../../images/careers.jpg";
import OurValues from "../../images/about2.jpg";
import map from "../../images/icons/map-pin.svg";
import LifeAt from "../../images/itelasoft-life.jpg";
import interImg from "../../images/InternImg.png"
import IntroBanner from "../../components/IntroBannerRevamp";

const Careers = () => {
  const [careers, setCareers] = useState([]);
  useEffect(async () => {
    try {
      const results = await axios.get(
        "https://analytics-internal.itelasoft.com.au/PublicData/JobOpenings",
        {
          headers: {
            "x-api-key": `6fa78d2c16d34c6aa31bd6073ae86b5b`,
          },
        }
      );
      setCareers(results.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const data = useStaticQuery(graphql`
    query {
      # Existing queries for heroImg, OurValues, LifeAt, and JobOpen
      heroImg: file(relativePath: { eq: "about2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 542, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      OurValues: file(relativePath: { eq: "about2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LifeAt: file(relativePath: { eq: "about2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      JobOpen: file(relativePath: { eq: "Internship.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 385, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      # New query for Team data
      TeamQuery: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/careers/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              path
              applylink
            }
          }
        }
      }
    }
  `);

  return (
    <Layout bodyClass="page-careers">
      <Seo
        title="Careers"
        description="Are you a
            passionate and committed technology enthusiast? We have room for you
            in our growing team!"
      />

      <IntroBanner
        title="Careers"
        subtitle="At iTelaSoft, the leaders of today inspire and support the superstars of tomorrow"
        image={hero}
      />

      {/* <Container>
        <div
          className="section-careers-hero section-padding"
          id="section-careers-hero"
        >
          <div className="page-careers-detail-wrap">
            <div className="page-careers-detail">
              <div className="page-careers-hero-title">Careers</div>
              <div className="page-careers-dec">
                At iTelaSoft, the leaders of today inspire and support the
                superstars of tomorrow
              </div>
            </div>
          </div>
          <div className="page-careers-image">
            <Img
              fluid={data.heroImg.childImageSharp.fluid}
              loading="eager"
              fadeIn={true}
              alt="Hero"
            /> 
            <img src={hero} alt="hero-img" className="img-fluid" />
          </div>
        </div>
      </Container> */}
      <ContrastSection
        title="If you have a passion for IT, you’ve come to the right place!"
        para="Founded in 2009, we are a rapidly growing dynamic software
        development company delivering innovative technological
        solutions that solve real business problems – from the simple to
        the complex."
      />
      <Container>
        <div
          className="section-careers-values-wrap section-padding"
          id="section-careers-values"
        >
          <div className="section-careers-values top">
            <div className="section-careers-values-image">
              {" "}
              {/* <Img
                fluid={data.OurValues.childImageSharp.fluid}
                loading="eager"
                fadeIn={true}
                alt="Hero"
              /> */}
              <img src={OurValues} alt="hero-img" className="img-fluid" />
            </div>
            <div className="section-careers-values-text">
              <div className="section-careers-values-title">Our values</div>
              <div className="page-careers-dec">
                Honesty, respect and fairness is in our DNA. We always act with
                integrity and are committed to delivering exceptional outcomes
                for our clients.
              </div>
            </div>
          </div>

          <div className="section-careers-values bottom">
            <div className="section-careers-values-text">
              <div className="section-careers-values-title">
                Life at iTelaSoft
              </div>
              <div className="page-careers-dec">
                We don’t believe in complex hierarchies, and we empower everyone
                who joins us to take ownership of their work and put their best
                foot forward for our customers. Our learning based and
                supportive culture enables us to coach and mentor team members
                to grow in experience, knowledge and confidence.
              </div>
            </div>
            <div className="section-careers-values-image">
              {" "}
              <img src={LifeAt} alt="hero-img" className="img-fluid" />
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="p-0">
        <div className="section-globle-teams section-padding">
          <Container>
            <div className="section--globle-teams-title">
              We are a global team who is always learning, optimising and
              improving.
            </div>
            <div className="page-careers-dec">
              Hear from some of our leaders, team members and clients,
            </div>

            <div className="wrap-video-out">
              <VideoOut videoId="-Eu-LvwDfYg" />
            </div>
          </Container>
        </div>
      </Container>
      <Container className="section-job-openings section-padding pb-1">
        <div className="careers-section">
          <h4 className="title title-1 mt-2 mb-3">
            We are looking for the following roles
          </h4>

          {/* card-deck */}

          <div class="card-deck carrer-card-deck">
            {careers.map((data) => (
              <React.Fragment key={data.id}>
                {data.publish ? (
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title"> {data.title}</h5>
                      {data.description ? (
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: data.description,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="card-footer">
                      <div>
                        <img className="map" src={map} />
                        <span className="location-text">
                          {data.location.split(",").join(", ")}
                        </span>
                      </div>
                      <Link
                        className="btn btn-outline"
                        to={
                          "/careers/apply/?id=" +
                          data.id +
                          "&title=" +
                          data.title
                        }
                      >
                        Apply Now
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            ))}
          </div>

          {/* Internship section */}
          {/* <div class="card-deck carrer-card-deck">
            <div class="card intetn-card">
              <div
                className="col-lg-12 card-top-line"
                style={{
                  backgroundColor: "#FFC700",
                  height: "16px",
                  borderRadius: "0.5rem 0.5rem 0 0",
                }}
              ></div>
              <div class="card-body inter-card-body">
                <div className="card-content-wrapper">
                  <div className="col-lg-4 col-md-6 card-image-wrapper d-none d-lg-flex d-md-flex">
                    <img
                      src={interImg}
                      alt="interImg"
                      className="card-img img-fluid"
                    />
                  </div>
                  <div className="col-lg-8 col-md-6 card-text-wrapper">
                    <h5 class="card-title">Internships at Itelasoft</h5>
                    <div className="description">
                      We offer exciting internship opportunities for motivated
                      individuals looking to gain valuable work experience in a
                      dynamic and innovative environment. We believe that
                      internships provide an excellent platform for students and
                      recent graduates to apply their academic knowledge,
                      develop essential skills, and explore their career
                      interests.We are excited to welcome talented individuals
                      who are eager to make a difference and embark on a
                      rewarding journey with us.
                    </div>
                    <div class="card-footer intern-card-footer mt-1">
                      <div>
                        <img
                          class="map intern-location-icon"
                          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItbWFwLXBpbiI+PHBhdGggZD0iTTIxIDEwYzAgNy05IDEzLTkgMTNzLTktNi05LTEzYTkgOSAwIDAgMSAxOCAweiI+PC9wYXRoPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTAiIHI9IjMiPjwvY2lyY2xlPjwvc3ZnPg=="
                        />
                        <span className="location-text">
                          <span>Sri Lanka, India</span>
                        </span>
                      </div>
                      <Link
                        className="btn btn-outline"
                        to="/careers/Internship"
                      >
                        Apply Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Container>
      <Container className="section-Internships section-padding pt-0">
        <div className="section-Internships-bottom">
          <div className="affliated-Universities">
            <div className="affliated-Universities-title">
              Affliated Universities
            </div>
            <div className="affliated-Universities-img-set">
              <div className="uni-logo">
                <img
                  alt="affliated-University"
                  className="img-fluid"
                  src={Iit}
                />
              </div>
              <div className="uni-logo">
                <img
                  alt="affliated-University"
                  className="img-fluid"
                  src={Sliit}
                />
              </div>
              <div className="uni-logo">
                <img
                  alt="affliated-University"
                  className="img-fluid"
                  src={Mora}
                />
              </div>
              <div className="uni-logo">
                <img
                  alt="affliated-University"
                  className="img-fluid"
                  src={Uva}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container className="wrap-section-know-more p-0 mb-3">
        <div className="getting-touch section-padding">
          <GetInTouchbtn
            textdce={"Want to know more?"}
            texturl={" Get in touch at careers@itelasoft.com"}
            textbtn={"Get in touch"}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default Careers;
