import React from "react";
import { Container } from "reactstrap";
import SvgIconsSet from "./SvgIconsSet";

const GetInTouchbtn = ({ textdce, texturl }) => {
  return (
    <Container className="getting-touch">
      <div className="getting-touch-dec">
        <div className="getting-touch-left">
          <div className="getting-touch-left-title">{textdce}</div>
          <div className="getting-touch-left-subtitle">{texturl}</div>
        </div>
        <div className="getting-touch-right">
          <a className="btn btn-primary btn-icon-button" href="/contact/">
          <span className="btn-text">Get in touch</span>
            <span className="btn-icon">
              <SvgIconsSet.SideArrow />
            </span>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default GetInTouchbtn;
